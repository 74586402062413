@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GreatVibes';
  src: local('GreatVibes'), url(./assets/fonts/GreatVibes.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Lobster';
  src: local('Lobster'), url(./assets/fonts/Lobster.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'LobsterTwo';
  src: local('LobsterTwo-Regular'), url(./assets/fonts/LobsterTwo-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'LobsterTwoBold';
  src: local('LobsterTwo-Bold'), url(./assets/fonts/LobsterTwo-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html,
body,
#root {
  height: 100%;
  background-color: #000000;
}

.reactEasyCrop_CropArea {
  color: rgba(0, 0, 0, 0.8) !important;
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

#header-shape-gradient {
  --color-stop: #9350ff;
  --color-bot: #0787ff;
}

/* #header-shape-gradient {
  --color-stop: #ff0800;
  --color-bot: #300505;
} */

.gradient-bg {
  fill: url(#header-shape-gradient) #fff;
}

@keyframes navbarShow {
  from {
    transform: translateY(-65px);
  }
}
@keyframes navbarHide {
  to {
    transform: translateY(-65px);
  }
}
